import {
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EvalLicensedModule } from "components/Evaluation/Stores/EvalStore";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import { WorkflowReviewStore } from "../Stores/WorkflowReviewStore";
import WFRQuestionAnswerList from "./WFRQuestionAnswerList/WFRQuestionAnswerList";
import WFRDisputedMessage from "./WFRModuleCard/WFRDisputedMessage";
import WFRModuleCardHeader from "./WFRModuleCard/WFRModuleCardHeader";
import WFRModuleCardFooter from "./WFRModuleCard/WFRModuleCardFooter";
import { WFRInstanceLevelState } from "./WFRInstanceLevel/WFRInstanceLevel";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import WFRResultBanner from "./WFRResultBanner";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import { styled } from "@material-ui/core";

export interface WFRNonBossLevelApproveRejectModuleCardProps {
    title: string;
    reviewed: "approve" | "reject" | "complete" | null;
    level?: number;
    lineClamp?: number;
    reviewerName?: string;
    verifiedDate?: string;
    fullWidth?: boolean;
    subContent?: string;
    customCollapseContent?: any;
    onConfirm?: () => void;
    onReject?: () => void;
    onNoteChange?: (arg?: string) => void;
    confirmationText?: string;
    rejectionText?: string;
    reviewerNotes?: string;
    customRootStyles?: CSSProperties;
    isOpen?: boolean;
    module?: EvalLicensedModule | null;
    loading?: boolean;
    state?: WFRInstanceLevelState;
    requiredQuestions: boolean;
    rejectionHidden?: boolean;
}

const CollapseContainer = styled(Grid)(() => ({}));

const ToggleOpenButton = styled(Button)(() => ({
    borderRadius: "100%",
    height: "30px",
    width: "30px",
    minWidth: "30px",
}));

const SectionTitle = styled(Grid)(() => ({
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginBottom: "16px",
}));

const WFRNonBossLevelApproveRejectModuleCard: React.FC<WFRNonBossLevelApproveRejectModuleCardProps> =
    observer((props) => {
        const store = useStore(WorkflowReviewStore);
        const [open, toggleOpen] = React.useState<boolean>(
            props.isOpen ?? false,
        );

        const hideConfirmationButton =
            store.workflowInfo?.workflowType ===
            WorkflowConditionType.ScoreEscalation;

        return (
            <Card
                style={props.customRootStyles}
                sx={{
                    width: props.fullWidth ? "100%" : "",
                    height: "min-content",
                    marginBottom: "1.25rem",
                }}
            >
                <Grid
                    sx={{
                        height: "0.75rem",
                        backgroundColor: theme.palette.blue.main,
                    }}
                />
                <CardContent
                    sx={{
                        padding: "1rem",
                        paddingBottom: "0.75rem !important",
                    }}
                >
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent="space-between"
                                alignItems="center"
                                alignContent="center"
                            >
                                {!props.reviewed && (
                                    <>
                                        {open ? (
                                            <ToggleOpenButton
                                                onClick={(e) =>
                                                    toggleOpen(false)
                                                }
                                            >
                                                <ExpandLessIcon />
                                            </ToggleOpenButton>
                                        ) : (
                                            <ToggleOpenButton
                                                onClick={(e) =>
                                                    toggleOpen(true)
                                                }
                                            >
                                                <ExpandMoreIcon />
                                            </ToggleOpenButton>
                                        )}
                                    </>
                                )}
                            </Grid>

                            {store.currentLevel &&
                                (store.workflowInfo?.workflowInfo.status ===
                                    WorkflowStatus.Closed ||
                                    store.currentLevel?.result) && (
                                    <Grid item xs={12}>
                                        <WFRResultBanner
                                            result={store.currentLevel.result}
                                            completedBy={
                                                store.currentLevel.completedBy
                                            }
                                            verifiedDate={
                                                store.currentLevel
                                                    .evaluationModule
                                                    ?.modifiedOn
                                            }
                                        />
                                    </Grid>
                                )}

                            {props.subContent && (
                                <Typography component="div">
                                    {props.subContent}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>

                    {!props.reviewed ? (
                        <CollapseContainer container item xs={12}>
                            <Collapse
                                in={open}
                                timeout={theme.transitions.duration.standard}
                                collapsedSize={0}
                                style={{ width: "100%" }}
                            >
                                {props.customCollapseContent ? (
                                    props.customCollapseContent
                                ) : (
                                    <Grid container item xs={12}>
                                        {store.currentLevel &&
                                            (store.workflowInfo?.workflowInfo
                                                .status ===
                                                WorkflowStatus.Closed ||
                                                store.currentLevel?.result) && (
                                                <Grid item xs={12}>
                                                    <WFRResultBanner
                                                        result={
                                                            store.currentLevel
                                                                .result
                                                        }
                                                        completedBy={
                                                            store.currentLevel
                                                                .completedBy
                                                        }
                                                        verifiedDate={
                                                            store.currentLevel
                                                                .evaluationModule
                                                                ?.modifiedOn
                                                        }
                                                    />
                                                </Grid>
                                            )}
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            {props.module ? (
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="flex-start"
                                                    alignItems="stretch"
                                                >
                                                    <SectionTitle item xs={12}>
                                                        <Typography
                                                            variant={"h1"}
                                                        >
                                                            {props.module?.name}
                                                        </Typography>
                                                    </SectionTitle>
                                                    <WFRModuleCardHeader
                                                        module={props.module}
                                                    />
                                                    <WFRDisputedMessage
                                                        module={props.module}
                                                    />
                                                    <WFRQuestionAnswerList
                                                        disputeHasStarted={
                                                            store.disputeHasStarted
                                                        }
                                                        module={props.module}
                                                        isEditable={
                                                            store.userCanEditWorkflow
                                                        }
                                                        isWorkflowModule={true}
                                                    />
                                                    <WFRModuleCardFooter
                                                        hideScore
                                                        module={props.module}
                                                    />
                                                </Grid>
                                            ) : (
                                                <AcxMainTextField
                                                    id={"reviewer-notes"}
                                                    rows={3}
                                                    rowsMax={5}
                                                    labelText={"Add A Note"}
                                                    multiline
                                                    value={
                                                        props.reviewerNotes ??
                                                        ""
                                                    }
                                                    onChange={(evt) =>
                                                        props.onNoteChange?.(
                                                            evt.target.value,
                                                        ) as any
                                                    }
                                                    textareaRootStyles={{
                                                        marginRight: "0px",
                                                    }}
                                                />
                                            )}
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="flex-end"
                                        >
                                            {!props.rejectionHidden && (
                                                <AcxButton
                                                    buttonDisabled={
                                                        props.requiredQuestions ||
                                                        !store.userCanEditWorkflow ||
                                                        props.loading ||
                                                        store.currentLevelState ===
                                                            "boss"
                                                    }
                                                    tooltip={
                                                        props.requiredQuestions
                                                            ? "Required questions must be answered before module can be completed"
                                                            : undefined
                                                    }
                                                    //color="default"
                                                    fullWidth={false}
                                                    leftRightSpacing={0}
                                                    onClick={props.onReject}
                                                    loading={props.loading}
                                                    rootStyle={{
                                                        backgroundColor:
                                                            "#F55656",
                                                    }}
                                                >
                                                    {props.rejectionText ??
                                                        "Reject"}
                                                </AcxButton>
                                            )}
                                            {!hideConfirmationButton && (
                                                <AcxButton
                                                    buttonDisabled={
                                                        props.requiredQuestions ||
                                                        !store.userCanEditWorkflow ||
                                                        props.loading
                                                    }
                                                    tooltip={
                                                        props.requiredQuestions
                                                            ? "Required questions must be answered before module can be completed"
                                                            : undefined
                                                    }
                                                    color="secondary"
                                                    fullWidth={false}
                                                    leftRightSpacing={0}
                                                    onClick={props.onConfirm}
                                                    loading={props.loading}
                                                    rootStyle={{
                                                        marginLeft: "0.5rem",
                                                    }}
                                                >
                                                    {props.confirmationText ??
                                                        "Approve"}
                                                </AcxButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Collapse>
                        </CollapseContainer>
                    ) : (
                        props.module && (
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                <SectionTitle item xs={12}>
                                    <Typography variant={"h1"}>
                                        {props.module?.name}
                                    </Typography>
                                </SectionTitle>
                                <WFRModuleCardHeader module={props.module} />
                                <WFRDisputedMessage module={props.module} />
                                <WFRQuestionAnswerList
                                    disputeHasStarted={store.disputeHasStarted}
                                    module={props.module}
                                    isEditable={store.userCanEditWorkflow}
                                    isWorkflowModule={true}
                                />
                                <WFRModuleCardFooter
                                    module={props.module}
                                    hideScore
                                />
                            </Grid>
                        )
                    )}
                </CardContent>
            </Card>
        );
    });

export default WFRNonBossLevelApproveRejectModuleCard;

import {ModelComputeOption} from "models/ModelCompute";
import {useEffect, useState} from "react";
import OrganizationService from "../Organization.service"

type Props = {
    id: string;
};

const useModelComputeOptions = ({ id }: Props) => {
    const [data, setData] = useState<ModelComputeOption[] | null>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!id){
            return;
        }
        let destroyed = false;
        const runFn = async () => {
            try{
                setIsLoading(true);
                const res = await OrganizationService.getModelComputeOptions(id);
                if (!destroyed){
                    setData(res);
                    setIsLoading(false);
                }
            } catch (err){
                console.error(err);
                setIsLoading(false);
            }
        };
        runFn();
        return () => {
            destroyed = true;
            setData(null);
        }
    }, [id]);
    
    return{
        data,
        isLoading
    };
};

export default useModelComputeOptions;
import { ThreadActionsMap } from "components/Agent/Models/ThreadAction";
import BaseService from "./BaseService";
import { ThreadServerSuggestion } from "components/Agent/Models/ThreadSuggestion";

type NewThreadPayload = {
    id: string;
};

export interface StreamingTokenResult {
    text: string;
    actions?: ThreadActionsMap;
    suggestions?: ThreadServerSuggestion[];
}

export class AcxAgentService extends BaseService {
    protected urlBase = "api/aiagent";

    public async streamingStartChatThread(): Promise<NewThreadPayload> {
        const urlParams = new URLSearchParams();
        const apiPath = "chat/start-stream";

        const response = await this.post<NewThreadPayload>(
            "",
            urlParams,
            apiPath,
        );

        return response;
    }

    public async submitMessage(request: { threadId: string; body: string }) {
        const urlParams = new URLSearchParams();
        const apiPath = `chat/${request.threadId}/ask`;

        const response = await this.post<StreamingTokenResult>(
            JSON.stringify({ input: request.body }),
            urlParams,
            apiPath,
        );

        return response;
    }

    public async *streamingSubmitMessage(request: {
        threadId: string;
        body: string;
    }): AsyncIterable<StreamingTokenResult> {
        const urlParams = new URLSearchParams();
        const apiPath = `chat/${request.threadId}/ask-stream`;

        const response: Response = await this.post(
            JSON.stringify({ input: request.body }),
            urlParams,
            apiPath,
            undefined,
            undefined,
            undefined,
            true,
        );

        const reader = response.body?.getReader();
        if (!reader) {
            return console.error("No response reader.");
        }

        const decoder = new TextDecoder();

        async function readCurrentValue(): Promise<
            StreamingTokenResult | undefined
        > {
            let currentRawValue: string = "";
            try {
                if (!reader) return;
                const { done, value } = await reader.read();
                if (done) return;

                const rawValue = decoder.decode(value);
                currentRawValue = rawValue;

                return JSON.parse(currentRawValue);
            } catch (error) {
                if (!currentRawValue) return;
                // we likely received more than one json chunk in this message,
                // we will attempt to recover here
                const jsonParts = currentRawValue.match(/{[^}]*}/g);
                if (!jsonParts) return;

                let result: StreamingTokenResult = { text: "" };
                try {
                    for (const json of jsonParts) {
                        const chunk = JSON.parse(json) as StreamingTokenResult;
                        result.text += chunk.text;
                    }
                } finally {
                    return result;
                }
            }
        }

        while (true) {
            const value = await readCurrentValue();
            if (!value) break;
            yield value;
        }

        reader.releaseLock();

        // try {
        //     while (true) {
        //         const { done, value } = await reader.read();
        //         if (done) break;

        //         const rawValue = decoder.decode(value);
        //         currentRawValue = rawValue;

        //         yield JSON.parse(rawValue);
        //     }
        // } catch (error) {
        //     console.log(
        //         "Likely json failed to parse, heres the value it attempted to parse: ",
        //         currentRawValue,
        //         "Here is the error: ",
        //         error,
        //     );
        //     yield currentRawValue;
        // } finally {
        //     reader.releaseLock();
        // }
    }

    public async generateCoachingNotes(agentId: string, evaluationId: string) {
        const apiPath = `generate-coaching-notes`;
        const result = await this.post<{
            doWellNotes: string;
            improvementNotes: string;
        }>(
            JSON.stringify({ agentId, evaluationId }),
            new URLSearchParams(),
            apiPath,
        );
        return result;
    }
}

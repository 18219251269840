import { observer } from "mobx-react-lite";
import React, { useLayoutEffect } from "react";
import { Route, Routes } from "react-router";
import Organization from "../../../../models/Organization";
import { AuthStore } from "../../../../stores/AuthStore";
import { useStore } from "../../../../utils/useStore";
import OrganizationAgents from "./OrganizationAgents/OrganizationAgents";
import OrganizationAnalystGroups from "./OrganizationAnalystGroups/OrganizationAnalystGroups";
import OrganizationAnalysts from "./OrganizationAnalysts";
import OrganizationCustomerTypes from "./OrganizationCustomerTypes/OrganizationCustomerTypes";
import OrganizationDetail from "./OrganizationDetail";
import OrganizationKeyVaults from "./OrganizationKeyVaults";
import OrganizationLicense from "./OrganizationLicense";
import OrganizationModules from "./OrganizationModules/OrganizationModules";
import OrganizationMotfWrapper from "./OrganizationMotf/OrganizationMotfWrapper";
import OrganizationRoles from "./OrganizationRoles/OrganizationRoles";
import OrganizationServiceHierarchy from "./OrganizationServiceHierarchy/OrganizationServiceHierarchy";
import OrganizationStorage from "./OrganizationStorage";
import IdentifiedTopicsWrapper from "./OrganizationTopics/IdentifiedTopics/IdentifiedTopicsWrapper";
import UnidentifiedTopicsWrapper from "./OrganizationTopics/UnIdentifiedTopics/UnidentifiedTopicsWrapper";
import OrganizationUsers from "./OrganizationUsers/OrganizationUsers";
import OrganizationWorkflows from "./OrganizationWorkflows/OrganizationWorkflows";

type Props = {
    organizationId: string;
};

const OrganizationDetailRoutes: React.FC<Props> = observer(
    ({ organizationId }) => {
        const orgStore = useStore(AuthStore).orgStore;

        useLayoutEffect(() => {
            orgStore?.onActiveOrgIdChange(organizationId);
            orgStore?.setGlobalOrganization(
                orgStore.organizations?.find(
                    (org) => org.id === organizationId,
                )! as Organization,
            );
        }, [orgStore, organizationId]);

        return (
            <Routes>
                <Route path="license" element={<OrganizationLicense />} />
                <Route
                    path="modules/*"
                    element={<OrganizationModules id={organizationId} />}
                />
                <Route
                    path="service-hierarchy"
                    element={
                        <OrganizationServiceHierarchy id={organizationId} />
                    }
                />
                <Route
                    path="users/*"
                    element={<OrganizationUsers id={organizationId} />}
                />
                <Route path="analysts" element={<OrganizationAnalysts />} />
                <Route
                    path="analyst-groups"
                    element={<OrganizationAnalystGroups id={organizationId} />}
                />
                <Route
                    path="roles"
                    element={<OrganizationRoles id={organizationId} />}
                />
                <Route path="storage" element={<OrganizationStorage />} />
                <Route path="key-vaults" element={<OrganizationKeyVaults />} />
                <Route
                    path="contact-types"
                    element={<OrganizationCustomerTypes id={organizationId} />}
                />
                <Route
                    path="agents"
                    element={<OrganizationAgents id={organizationId} />}
                />
                <Route
                    path="workflows"
                    element={<OrganizationWorkflows id={organizationId} />}
                />
                <Route
                    path="topics/*"
                    element={<IdentifiedTopicsWrapper id={organizationId} />}
                ></Route>
                <Route
                    path="uidtopics/*"
                    element={<UnidentifiedTopicsWrapper id={organizationId} />}
                ></Route>
                <Route
                    path="motf/*"
                    element={<OrganizationMotfWrapper id={organizationId} />}
                />
                <Route
                    path="*"
                    element={<OrganizationDetail id={organizationId} />}
                />
            </Routes>
        );
    },
);

export default OrganizationDetailRoutes;

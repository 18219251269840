import React from "react";
import Markdown from "markdown-to-jsx";
import { UserMessage } from "./UserMessage";
import { AgentMessage } from "./AgentMessage";
import { MessageAuthor } from "../Models/MessageAuthor";
import { IThreadMessage } from "../Models/ThreadMessage";
import { Link } from "react-router-dom";
import { styled } from "@mui/material";

type Props = {
    author: MessageAuthor;
} & IThreadMessage;

const UlOverride = styled("ul")(({ theme }) => ({
    paddingLeft: theme.spacing(2),
}));

function LinkOverride(props: React.PropsWithChildren<{ href: string }>) {
    return <Link to={props.href}>{props.children}</Link>;
}

const Message: React.FC<Props> = (props) => {
    const isUserMessage = props.author === MessageAuthor.User;

    const ContentRenderer = isUserMessage ? React.Fragment : Markdown;
    const MessageContent = isUserMessage ? UserMessage : AgentMessage;

    return (
        <MessageContent item className={"pendo-ignore"}>
            <ContentRenderer
                options={{ overrides: { a: LinkOverride, ul: UlOverride } }}
            >
                {props.content}
            </ContentRenderer>
        </MessageContent>
    );
};

export default Message;

import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { PipelineProcessTypes } from "models/DataProcesses";
import { useEffect, useState } from "react";
import { useStore } from "utils/useStore";
import OrganizationService from "../Organization.service";
import { Organization } from "../types/Organization.type";

export const DB_CONNECTION_STATUS = {
    DEFAULT: "Default",
    LOADING: "Loading",
    SUCCESSFUL: "Successful",
    ERROR: "Error",
} as const;

export type DbConnectionStatusType =
    typeof DB_CONNECTION_STATUS[keyof typeof DB_CONNECTION_STATUS];

type Props = {
    id?: string;
    refetch?: () => void;
};

const useOrganization = ({ id }: Props) => {
    const messageStore = useStore(MessageStore);
    const [organization, setOrganization] = useState<Organization | null>();
    const [isLoading, setIsLoading] = useState(false);
    const [dbConnectionStatus, setDbConnectionStatus] =
        useState<DbConnectionStatusType>(DB_CONNECTION_STATUS.DEFAULT);

    useEffect(() => {
        if (!id) {
            return;
        }

        let destroyed = false;
        const runFn = async () => {
            try {
                setIsLoading(true);
                const res = await OrganizationService.getOrganization(id);
                res.dataProcessingOptions = res.licensedData
                    ?.filter((ld) => ld.isActive)
                    ?.map((ld) => ld.option.processingOption);
                if (!res.pipelineProcessingOption) {
                    res.pipelineProcessingOption =
                        PipelineProcessTypes.Automatic;
                }
                res.modelComputeOptions = res.licensedModelCompute
                    ?.filter((mc) => mc.isActive)
                    ?.map((mc) => mc.modelOption);
                if (res.organizationConfigurations?.evalAcknowledgeDays) {
                    res.evalAcknowledgeDays = Number(
                        res.organizationConfigurations.evalAcknowledgeDays,
                    );
                }
                if (res.organizationConfigurations?.weeklyInteractionsMin) {
                    res.weeklyInteractionsMin = Number(
                        res.organizationConfigurations.weeklyInteractionsMin,
                    );
                }
                if (res.organizationConfigurations?.weeklyInteractionsMax) {
                    res.weeklyInteractionsMax = Number(
                        res.organizationConfigurations.weeklyInteractionsMax,
                    );
                }
                if (!destroyed) {
                    setOrganization(res);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };
        runFn();
        return () => {
            destroyed = true;
            setOrganization(null);
        };
    }, [id]);

    const saveOrganization = async (val: Organization) => {
        const res = await OrganizationService.saveOrganization(val);
        res.dataProcessingOptions = res.licensedData
            ?.filter((ld) => ld.isActive)
            ?.map((ld) => ld.option.processingOption);
        if (val.serviceSetup) {
            res.serviceSetup = res.serviceSetup || val.serviceSetup;
        }
        return res;
    };

    const checkDbConnection = async (
        databaseConnectionString: string,
        databaseVersion: number,
    ) => {
        try {
            setDbConnectionStatus(DB_CONNECTION_STATUS.LOADING);
            const res = await OrganizationService.getCheckDbConnection(
                databaseConnectionString,
                databaseVersion,
            );
            if (res) {
                setDbConnectionStatus(DB_CONNECTION_STATUS.SUCCESSFUL);
                messageStore.logMessage(
                    "Successfully connected to Database",
                    "success",
                );

                return res;
            }
        } catch (err) {
            setDbConnectionStatus(DB_CONNECTION_STATUS.ERROR);
            messageStore.logError(
                "Could Not Connect to DB, Please check the DB Connection String and DB Version",
            );
            setTimeout(() => {
                setDbConnectionStatus(DB_CONNECTION_STATUS.DEFAULT);
            }, 3000);
        }
    };

    return {
        organization,
        saveOrganization,
        isLoading,
        checkDbConnection,
        dbConnectionStatus,
    };
};

export default useOrganization;

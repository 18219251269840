import { styled } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import AgentStore from "./AgentStore";
import { AgentLogo } from "./Branding/AgentLogo";

const AgentToggleButton = styled(AcxButton)(() => ({
    background:
        "linear-gradient(131deg, #32D1B7 3.43%, #349AC7 70.89%, #3664D6 100.5%)",
    padding: 0,
}));

const AgentChatMenuIcon = observer(() => {
    const agentStore = useStore(AgentStore);
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("View Ava")) return null;

    return (
        <div style={{ position: "relative", paddingRight: "5px" }}>
            <AgentToggleButton
                leftRightSpacing={0}
                fullWidth={false}
                onClick={() => agentStore.toggleAgentDrawer()}
                customRootStyles={{
                    width: "40px !important",
                    minWidth: "40px",
                }}
            >
                <AgentLogo color="white" width="100%" height="100%" />
            </AgentToggleButton>
        </div>
    );
});

export default AgentChatMenuIcon;

import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { AcxAgentService } from "services/AcxAgentService";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { AuthStore } from "stores/AuthStore";
import { initialSuggestionsSet, pickRandomSuggestions } from "./Suggestions";
import { Thread } from "./Models/Thread";
import type { ThreadSuggestion } from "./Models/ThreadSuggestion";
import LocalStorage from "stores/LocalStorage";
import { ResponseTime } from "./Models/ResponseTime";
import { ThreadMessage } from "./Models/ThreadMessage";

@AcxStore
export default class AgentChatStore extends BaseStore {
    readonly acxAgentService: AcxAgentService = new AcxAgentService();

    @observable thread: Thread; // = structuredClone(defaultThread);

    @observable agentSubmissions: string[] = [];
    @observable userSubmissions: string[] = [];
    @observable currentInput: string = "";

    @observable waitingForAgentResponse: boolean = false;

    // We use a interval here so that the observable changes each
    // second causing UI to re-render as needed for the different
    // message / loading states.
    @observable private responseTimeInSeconds?: number;
    private responseTimerHandle?: NodeJS.Timeout;

    readonly localStorage: LocalForage;

    constructor(public rootStore: IRootStore) {
        super("AgentChatStore");
        makeObservable(this);

        this.resetChat();

        this.localStorage = this.rootStore
            .getStore(LocalStorage)
            .getLocalStore();

        reaction(
            () => this.waitingForAgentResponse,
            (isWaiting) => {
                if (isWaiting) {
                    if (this.responseTimerHandle) return;
                    this.responseTimeInSeconds = 0;
                    this.responseTimerHandle = setInterval(
                        () =>
                            runInAction(() => {
                                if (this.responseTimeInSeconds === undefined)
                                    this.responseTimeInSeconds = 0;
                                this.responseTimeInSeconds += 1;
                            }),
                        1000,
                    );
                } else {
                    clearInterval(this.responseTimerHandle);
                    this.responseTimerHandle = undefined;
                    this.responseTimeInSeconds = undefined;
                }
            },
        );

        const evalStore = this.rootStore.getStore(EvalStore);
        const convosStore = this.rootStore.getStore(ConversationsStore);
        const authStore = this.rootStore.getStore(AuthStore);

        const sessionSuggestions = pickRandomSuggestions(initialSuggestionsSet);

        reaction(
            () => this.rootStore.activeLocation,
            () => {
                const recentAgentMessageGroup =
                    this.thread.recentAgentMessageGroup!;
                if (recentAgentMessageGroup.transientMessage)
                    recentAgentMessageGroup.clearSuggestions();
                recentAgentMessageGroup.clearTransientMessage();

                if (!this.thread.hasUserMessaged)
                    recentAgentMessageGroup.setSuggestions(sessionSuggestions);
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                evaluation: evalStore.currentEval,
                conversation: convosStore.selectedConversation,
            }),
            (args) => {
                if (!authStore.canUserEdit("Agent Coaching")) return;

                const recentAgentMessageGroup =
                    this.thread.recentAgentMessageGroup!;
                if (recentAgentMessageGroup.transientMessage)
                    recentAgentMessageGroup.clearSuggestions();
                recentAgentMessageGroup.clearTransientMessage();

                if (!args.evaluation && !args.conversation) return;

                let agentId: string | undefined;
                let agentName: string | undefined;
                let evaluationId: string | undefined;
                let evaluationNumber: number | undefined;

                if (!!args.evaluation) {
                    if (!!args.evaluation.agentCoachingId) return;
                    agentId = args.evaluation.interaction?.agentId;
                    agentName = args.evaluation.interaction?.agentName;
                    evaluationId = args.evaluation.id;
                    evaluationNumber = args.evaluation.evaluationQbId;
                } else if (!!args.conversation) {
                    if (!!args.conversation.evaluationAgentCoachingId) return;
                    agentId = args.conversation.agentId;
                    agentName = `${args.conversation.agentFirstName} ${args.conversation.agentLastName}`;
                    evaluationId = args.conversation.evaluationId;
                    evaluationNumber = args.conversation.evaluationNumber;
                }

                if (!agentId || !evaluationId || !evaluationNumber) return;

                recentAgentMessageGroup.setTransientMessage(
                    "Noticed you're viewing a conversation, here are some things I can help with:",
                );
                recentAgentMessageGroup.setSuggestions([
                    {
                        content: "Coach the agent on this conversation",
                        action: (store, suggestion) => {
                            store.thread
                                .addUserMessageGroup()
                                .addMessage(suggestion.content);
                            this.generateCoachingNotesAction(
                                agentId!,
                                agentName!,
                                evaluationId!,
                                evaluationNumber!,
                            );
                        },
                    },
                ]);
            },
            { fireImmediately: true },
        );
    }

    @computed get responseTime() {
        const timeInSeconds = this.responseTimeInSeconds;
        if (timeInSeconds === undefined) return undefined;

        if (timeInSeconds > 30) return ResponseTime.Long;
        else if (timeInSeconds > 15) return ResponseTime.Medium;
        else return ResponseTime.Short;
    }

    @action
    resetChat() {
        this.thread = new Thread();

        const group = this.thread.addAgentMessageGroup();
        group.addMessage(
            "Hello! I'm Ava, your Authenticx AI assistant. I'm here to help you discover meaningful insights. How can I assist you today?",
        );
        group.setSuggestions(pickRandomSuggestions(initialSuggestionsSet));
    }

    @action
    onChangeHandler(input: string) {
        this.currentInput = input;
    }

    @action public async handleSuggestion(suggestion: ThreadSuggestion) {
        if (!this.thread.id) {
            const threadResponse =
                await this.acxAgentService.streamingStartChatThread();
            this.thread.id = threadResponse.id;
        }

        this.thread.recentAgentMessageGroup!.commitTransientMessage();
        suggestion.action(this, suggestion);
    }

    private async generateCoachingNotesAction(
        agentId: string,
        agentName: string,
        evaluationId: string,
        evaluationNumber: number,
    ) {
        this.waitingForAgentResponse = true;
        try {
            const result = await this.acxAgentService.generateCoachingNotes(
                agentId,
                evaluationId,
            );

            await this.localStorage.setItem("generatedCoachingNotes", result);

            window
                .open(
                    `${window.location.origin}/app/agentcoaching/evaluationreview?agentId=${agentId}&agentName=${agentName}&evaluationId=${evaluationId}&evaluationNumber=${evaluationNumber}`,
                    "_blank",
                )
                ?.focus();

            this.thread
                .addAgentMessageGroup()
                .addMessage(
                    "I've opened a new tab with the generated coaching notes.",
                );
        } catch {
        } finally {
            this.waitingForAgentResponse = false;
        }
    }

    @action
    async submitInput(manualInput?: string) {
        const input = manualInput ?? this.currentInput;
        this.currentInput = "";
        if (!input) return;

        const recentAgentMessages = this.thread.recentAgentMessageGroup!;
        recentAgentMessages.commitTransientMessage();

        this.thread.addUserMessageGroup().addMessage(input);

        this.waitingForAgentResponse = true;

        if (!this.thread.id) {
            const threadResponse =
                await this.acxAgentService.streamingStartChatThread();
            this.thread.id = threadResponse.id;
        }

        const response = await this.acxAgentService.submitMessage({
            threadId: this.thread.id,
            body: input,
        });

        this.waitingForAgentResponse = false;

        const messageGroup = this.thread.addAgentMessageGroup();
        messageGroup.addMessage(response.text);
        if (response.suggestions) {
            for (const suggestion of response.suggestions)
                messageGroup.addServerSuggestion(suggestion);
        }
        // messageGroup.setActions(response.actions)
    }

    @action
    async streamingSubmitInput(manualInput?: string) {
        // instead of manually updating references to streamingSubmitInput
        // we just redirect to the non-streaming version.
        // return this.submitInput(manualInput);

        const input = manualInput ?? this.currentInput;
        this.currentInput = "";
        if (!input) return;

        const recentAgentMessages = this.thread.recentAgentMessageGroup!;
        recentAgentMessages.commitTransientMessage();

        this.thread.addUserMessageGroup().addMessage(input);

        this.waitingForAgentResponse = true;

        if (!this.thread.id) {
            const threadResponse =
                await this.acxAgentService.streamingStartChatThread();
            this.thread.id = threadResponse.id;
        }

        const stream = this.acxAgentService.streamingSubmitMessage({
            threadId: this.thread.id,
            body: input,
        });

        let isFirst = true;
        let message: ThreadMessage | undefined = undefined;
        for await (const chunk of stream) {
            if (isFirst) {
                this.waitingForAgentResponse = false;
                message = this.thread.addAgentMessageGroup().addMessage("");
            }

            if (!message) continue;
            isFirst = false;

            if (!chunk.actions && !chunk.suggestions) {
                if (!chunk.text) continue;
                message.appendContent(chunk.text);
                continue;
            }

            // if (chunk.actions) {
            //     this.thread.recentAgentMessageGroup?.setActions(chunk.actions);
            // }

            if (chunk.suggestions) {
                for (const suggestion of chunk.suggestions)
                    this.thread.recentAgentMessageGroup?.addServerSuggestion(
                        suggestion,
                    );
            }
        }
    }
}

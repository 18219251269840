import { observer } from "mobx-react";
import React, { useState } from "react";
import { WorkflowReviewStore } from "../../Stores/WorkflowReviewStore";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import { EvalLicensedModule } from "components/Evaluation/Stores/EvalStore";
import { useStore } from "utils/useStore";
import WFRNonBossLevelApproveRejectModuleCard from "../WFRNonBossLevelApproveRejectModuleCard";
import { UpdateWorkflow, WorkflowStore } from "../../Stores/WorkflowStore";
import WFRScoreEscalationAdvanceCard from "../WFRScoreEscalationAdvanceCard";
import WFRApproveRejectCompleteDialog, {
    WFRInstanceLevelResult,
} from "./WFRApproveRejectCompleteDialog";

export interface WFRInstanceLevelProps {
    levelType: WorkflowConditionType;
    state: WFRInstanceLevelState;
    workflowEvalModule: EvalLicensedModule | null;
    notes: string;
    result: "approve" | "reject" | null;
    completedBy: string;
    level: number;
    metadataId: string;
    instanceId: string;
    name?: string;
}

export type WFRInstanceLevelState = "complete" | "pending" | "boss";

const WFRInstanceLevel: React.FC<WFRInstanceLevelProps> = observer((props) => {
    const store = useStore(WorkflowReviewStore);
    const modulesStore = store.workflowStore;

    const [note, setNote] = useState(props.notes);
    const [approveRejectComplete, setApproveRejectComplete] =
        useState<WFRInstanceLevelResult>(null);
    const [open, setOpen] = useState(false);

    const APTLevelTitle = `APT Review - ${
        props.name ?? "Level " + (props.level + 1)
    } Reviewer`;

    const onApprove = () => {
        modulesStore.onCompleteLevel(
            props.instanceId,
            props.workflowEvalModule
                ? props.workflowEvalModule.evaluationModuleId
                : "",
            props.level,
            note,

            WorkflowStore.ApproveResult,
            store.moduleScore ?? undefined,
        );
        setOpen(false);
    };

    const onComplete = () => {
        modulesStore.onCompleteLevel(
            props.instanceId,
            props.workflowEvalModule
                ? props.workflowEvalModule.evaluationModuleId
                : "",
            props.level,
            note,
            WorkflowStore.CompletedResult,
            store.moduleScore ?? undefined,
        );
        setOpen(false);
    };

    const onReject = () => {
        modulesStore.onCompleteLevel(
            props.instanceId,
            props.workflowEvalModule
                ? props.workflowEvalModule.evaluationModuleId
                : "",
            props.level,
            props.notes,
            WorkflowStore.RejectResult,
            store.moduleScore ?? undefined,
        );
        setOpen(false);
    };

    const onSEApprove = () => {
        const score = store.moduleScore;
        if (score !== null) {
            store.workflowStore.onCompleteLevel(
                props.instanceId,
                store.workflowInfo?.workflowInfo.scoreEscalationModule.id ?? "",
                props.level,
                "",
                WorkflowStore.ApproveResult,
                score,
            );
        }
    };

    const onSEComplete = () => {
        const score = store.moduleScore;
        if (score !== null) {
            store.workflowStore.onCompleteLevel(
                props?.instanceId ?? "",
                props.workflowEvalModule
                    ? props.workflowEvalModule.evaluationModuleId
                    : "",
                props?.level ?? -1,
                "",
                WorkflowStore.CompletedResult,
                score,
            );
        }
    };

    const onDisputeApprove = () => {
        const scores = store.disputedModulesWithScores;
        if (scores !== null) {
            store.workflowStore.onCompleteDisputeLevel(
                props.instanceId,
                props.workflowEvalModule?.id ?? "",
                props.level,
                note,
                WorkflowStore.ApproveResult,
                scores,
            );
        }
    };

    const onDisputeComplete = () => {
        const scores = store.disputedModulesWithScores;
        if (scores !== null) {
            store.workflowStore.onCompleteDisputeLevel(
                props?.instanceId ?? "",
                props.workflowEvalModule?.id ?? "",
                props?.level ?? -1,
                note,
                WorkflowStore.CompletedResult,
                scores,
            );
        }
    };

    var requiredQuestions = props.workflowEvalModule
        ? store.isRequiredQuestionsForWorkflowModule?.(
              props.workflowEvalModule.evaluationModuleId,
          )
        : false;

    const viewingCurrentLevel =
        store.workflowInfo?.workflowInfo.currentLevel === store.stepperLevel;

    const showEditModule =
        ((props.levelType === WorkflowConditionType.ScoreEscalation &&
            props.state === "boss") ||
            props.levelType !== WorkflowConditionType.ScoreEscalation) &&
        props.state !== "complete";

    const showReadOnlyModule =
        props.state === "complete" &&
        props.levelType !== WorkflowConditionType.ScoreEscalation;

    return (
        <div>
            {showEditModule && (
                <WFRNonBossLevelApproveRejectModuleCard
                    key={props.level}
                    title={APTLevelTitle}
                    reviewed={props.result}
                    isOpen
                    state={props.state}
                    reviewerNotes={!props.result ? props.notes : undefined}
                    subContent={props.result ? props.notes : undefined}
                    onNoteChange={(value) => {
                        if (value !== undefined) {
                            setNote(value);
                        }
                    }}
                    onConfirm={() => {
                        setOpen(true);
                        setApproveRejectComplete(
                            props.state === "boss" ? "complete" : "approve",
                        );
                    }}
                    onReject={() => {
                        setOpen(true);
                        setApproveRejectComplete("reject");
                    }}
                    reviewerName={props.completedBy}
                    lineClamp={1}
                    customRootStyles={{
                        marginTop: "1.5rem",
                        width: "100%",
                    }}
                    confirmationText={
                        store.currentLevelState === "boss"
                            ? "Complete"
                            : props.levelType ===
                              WorkflowConditionType.EvaluationDispute
                            ? "Submit"
                            : "Advance"
                    }
                    rejectionText={"End Workflow"}
                    rejectionHidden={
                        props.state === "boss" ||
                        props.levelType ===
                            WorkflowConditionType.EvaluationDispute
                    }
                    module={props.workflowEvalModule}
                    loading={modulesStore.getTaskLoadingV2(UpdateWorkflow)}
                    requiredQuestions={requiredQuestions}
                />
            )}

            {showReadOnlyModule && (
                <WFRNonBossLevelApproveRejectModuleCard
                    key={props.level}
                    title={APTLevelTitle}
                    reviewed={props.result}
                    isOpen
                    state={props.state}
                    reviewerName={props.completedBy}
                    subContent={props.notes}
                    lineClamp={1}
                    customRootStyles={{
                        marginTop: "1.5rem",
                        width: "100%",
                    }}
                    module={props.workflowEvalModule}
                    requiredQuestions={requiredQuestions}
                />
            )}

            {props.levelType === WorkflowConditionType.ScoreEscalation && (
                <WFRScoreEscalationAdvanceCard
                    instanceId={props.instanceId}
                    level={props.level}
                    module={props.workflowEvalModule}
                    onConfirm={() => {
                        setOpen(true);
                        setApproveRejectComplete(
                            props.state === "boss" ? "complete" : "approve",
                        );
                    }}
                    buttonDisabled={
                        !viewingCurrentLevel ||
                        requiredQuestions ||
                        !store.userCanEditWorkflow ||
                        store.workflowStore.getTaskLoadingV2(UpdateWorkflow)
                    }
                    loading={store.workflowStore.getTaskLoadingV2(
                        UpdateWorkflow,
                    )}
                />
            )}
            <WFRApproveRejectCompleteDialog
                open={open}
                approveRejectComplete={approveRejectComplete}
                onApprove={
                    store.workflowInfo?.workflowType ===
                    WorkflowConditionType.ScoreEscalation
                        ? onSEApprove
                        : store.workflowInfo?.workflowType ===
                          WorkflowConditionType.EvaluationDispute
                        ? onDisputeApprove
                        : onApprove
                }
                onReject={onReject}
                onComplete={
                    store.workflowInfo?.workflowType ===
                    WorkflowConditionType.ScoreEscalation
                        ? onSEComplete
                        : store.workflowInfo?.workflowType ===
                          WorkflowConditionType.EvaluationDispute
                        ? onDisputeComplete
                        : onComplete
                }
                onClose={() => {
                    setOpen(false);
                    setApproveRejectComplete(null);
                }}
                buttonsDisabled={
                    requiredQuestions ||
                    !store.userCanEditWorkflow ||
                    modulesStore.getTaskLoadingV2(UpdateWorkflow)
                }
                state={props.state}
                conditionType={store.workflowInfo?.workflowType}
            />
        </div>
    );
});

export default WFRInstanceLevel;

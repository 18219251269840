import { action, makeObservable, observable } from "mobx";
import React from "react";
import { BaseStore } from "stores/BaseStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import AgentChat from "./AgentChat";

@AcxStore
export default class AgentStore extends BaseStore {
    readonly drawerStore: LayoutDrawerStore;

    @observable agentDrawerOpen: boolean = false;

    constructor(public rootStore: IRootStore) {
        super("AgentStore");
        makeObservable(this);

        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);
    }

    @action
    toggleAgentDrawer() {
        if (this.drawerStore.isOpen) {
            this.drawerStore.closeDrawer();
            this.drawerStore.restoreDefaults();
        } else {
            this.drawerStore.closeAndResetDrawer(true);
            this.drawerStore.restoreDefaults();

            this.drawerStore.setOffsetPixels(80);
            this.drawerStore.setCustomRootStyles({ zIndex: 1099 });
            this.drawerStore.setTransitionDuration(1000);
            this.drawerStore.setContentFactory(() => (
                <AgentChat close={() => this.drawerStore.closeDrawer()} />
            ));

            this.drawerStore.setOpen(true);
        }
    }
}

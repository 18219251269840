import type {
    ThreadSuggestionAction,
    ThreadSuggestion,
} from "./Models/ThreadSuggestion";

const submitSuggestionContentAction: ThreadSuggestionAction = (
    store,
    suggestion,
) => store.streamingSubmitInput(suggestion.content);

export const initialSuggestionsSet: ThreadSuggestion[] = [
    {
        content: "What are the top 5 topics in conversations with Eddies?",
        action: submitSuggestionContentAction,
    },
    {
        content: "What was the average call duration in the past month?",
        action: submitSuggestionContentAction,
    },
    {
        content:
            "What are the main topics in conversations with negative ending sentiment?",
        action: submitSuggestionContentAction,
    },
    {
        content: "What topics are causing an increase in call duration?",
        action: submitSuggestionContentAction,
    },
    {
        content: "What new topics are creating eddies?",
        action: submitSuggestionContentAction,
    },
    {
        content:
            "How many calls started with a positive sentiment but included an Eddy?",
        action: submitSuggestionContentAction,
    },
    {
        content: "Who has been calling this month?",
        action: submitSuggestionContentAction,
    },
    {
        content: "Who experiences the most Eddies?",
        action: submitSuggestionContentAction,
    },
    {
        content: "How much preventable talk time was there last week?",
        action: submitSuggestionContentAction,
    },
    { content: "What is an Eddy?", action: submitSuggestionContentAction },
    {
        content: "How does Authenticx reduce risk?",
        action: submitSuggestionContentAction,
    },
    {
        content: "Why is analyzing call data important?",
        action: submitSuggestionContentAction,
    },
];

function randomIndex(arr: unknown[]): number {
    return Math.floor(Math.random() * arr.length);
}

export function pickRandomSuggestions(
    options: ThreadSuggestion[],
): ThreadSuggestion[] {
    const firstIdx = randomIndex(options);
    let secondIdx: number;
    do {
        secondIdx = randomIndex(options);
    } while (firstIdx === secondIdx);

    return [options[firstIdx], options[secondIdx]];
}

import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import {
    AgentCoaching,
    AgentCoachingStatus,
} from "../../../../models/AgentModels/AgentCoaching";
import {
    AgentCoachingData,
    AgentCoachingService,
} from "../../../../services/AgentCoachingService";
import { BaseStore } from "../../../../stores/BaseStore";
import { AgentDashboardStore } from "components/Agents/Dashboard/Stores/AgentDashboardStore";
import { AuthStore } from "stores/AuthStore";
import { AcxAgentService } from "services/AcxAgentService";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import LocalStorage from "stores/LocalStorage";

@AcxStore
export class EvalReviewStore extends BaseStore {
    private readonly agentCoachingService: AgentCoachingService =
        new AgentCoachingService();
    private readonly acxAgentService: AcxAgentService = new AcxAgentService();
    private readonly localStorage: LocalForage;

    @observable focusAreaQuestions?: { value: string }[];

    /**
     * True when the user is editing a drafted review
     */
    @observable isEditing: boolean;
    @observable agentCoaching?: AgentCoaching;
    @observable targetedEvaluations?: AgentCoachingData[];
    @observable moduleScoresForEvaluations?: any[];

    @observable hasGeneratedCoachingNotes: boolean = false;
    @observable isCoached: boolean = false;

    constructor(private rootStore: IRootStore) {
        super("Eval Review");

        makeObservable(this);

        this.localStorage = this.rootStore
            .getStore(LocalStorage)
            .getLocalStore();

        this.isEditing = false;

        reaction(
            (r) => ({
                activeLocation: this.rootStore.activeLocation,
            }),
            (arg) => {
                if (
                    arg.activeLocation &&
                    arg.activeLocation.location !==
                        "/app/agentcoaching/evaluationreview"
                ) {
                    return;
                }

                this.setupAsyncTask("Load Evaluation Focus Areas", () =>
                    this.loadFocusAreaQuestions(),
                );
            },
            { fireImmediately: true },
        );
    }

    /**
     * Back url to use when a user has no history for the tab.
     * This allows the action buttons in agent coaching to properly send the user
     * back when thay are directed there in a new tab by the agent.
     */
    @computed get backUrl(): string | number {
        if (!this.agentCoaching) return -1;

        const authStore = this.rootStore.getStore(AuthStore);
        const orgId = authStore.orgStore.selectedOrganization?.id;
        if (!orgId) return -1;

        const agentStore = this.rootStore.getStore(AgentDashboardStore);
        const agentName = agentStore.agentName;
        if (!agentName) return -1;

        return `/app/agentcoaching/dashboard/${orgId}/${this.agentCoaching.agentId}/${agentName}`;
    }

    @action
    async initializeStore(
        agentId: string,
        evaluations: AgentCoachingData[],
        isCoached: boolean,
        agentCoachingDraft?: AgentCoaching,
    ) {
        this.hasGeneratedCoachingNotes = false;
        this.targetedEvaluations = evaluations;
        this.isCoached = isCoached;

        if (!agentCoachingDraft) {
            const firstRec = evaluations[0];

            this.agentCoaching = new AgentCoaching();
            this.agentCoaching.agentId = agentId;
            this.agentCoaching.evaluationId = evaluations.map(
                (item) => item.id,
            );
            this.agentCoaching.agentDoWellNotes = firstRec.agentDoWellNotes;
            this.agentCoaching.acknowledged = firstRec.acknowledged;
            this.agentCoaching.focusAreas = firstRec.focusAreas;
            this.agentCoaching.improvementNotes = firstRec.improvementNotes;

            if (firstRec.agentCoachingId) {
                this.agentCoaching.agentCoachingId = firstRec.agentCoachingId;
            }

            this.isEditing = false;
        } else {
            this.agentCoaching = agentCoachingDraft;
            this.isEditing = true;
        }

        this.moduleScoresForEvaluations =
            await this.agentCoachingService.getModuleScoresForEvaluations(
                evaluations.map((val) => val.id),
            );
    }

    @action
    async loadSingleEvalAndInitializeStore(
        agentId: string,
        agentName: string,
        evaluationId: string,
        evaluationNumber: string,
    ) {
        this.setupAsyncTask("Load Single Evaluation Review", async () => {
            await this.initializeStore(
                agentId,
                [
                    {
                        id: evaluationId,
                        evaluationQbId: Number(evaluationNumber),
                    },
                ],
                false,
            );

            this.rootStore.getStore(AgentDashboardStore).agentName = agentName;

            const coachingNotes = await this.localStorage.getItem<{
                doWellNotes: string;
                improvementNotes: string;
            }>("generatedCoachingNotes");

            if (coachingNotes) {
                this.hasGeneratedCoachingNotes = true;
                this.agentCoaching?.setDoWellNotes(coachingNotes.doWellNotes);
                this.agentCoaching?.setImprovementNotes(
                    coachingNotes.improvementNotes,
                );
            }
        });
    }

    @action
    async loadFocusAreaQuestions() {
        const res = await this.agentCoachingService.getEvaluationQuestions();
        this.focusAreaQuestions = res.map((q) => ({
            value: q.questionText,
        }));
    }

    @action
    async generateCoachingNotes() {
        this.setupAsyncTask("Generate Coaching Notes", async () => {
            if (
                !this.agentCoaching ||
                !this.targetedEvaluations ||
                this.targetedEvaluations.length !== 1
            )
                return;

            const singleEvaluation = this.targetedEvaluations[0];

            try {
                const result = await this.acxAgentService.generateCoachingNotes(
                    this.agentCoaching.agentId,
                    singleEvaluation.id,
                );

                this.agentCoaching.setDoWellNotes(result.doWellNotes);
                this.agentCoaching.setImprovementNotes(result.improvementNotes);

                runInAction(() => {
                    this.hasGeneratedCoachingNotes = true;
                });
            } catch (err) {
                this.rootStore
                    .getStore(MessageStore)
                    .logError(
                        "Failed to generate coaching notes. Please try again.",
                    );
            }
        });
    }

    @action
    async createAgentCoaching(asDraft?: boolean) {
        if (!this.agentCoaching) {
            console.warn("unable to create agent coaching");
            return true;
        }
        try {
            if (asDraft)
                await this.agentCoachingService.createCoachingAsDraft(
                    this.agentCoaching,
                );
            else
                await this.agentCoachingService.createCoachingAsCompleted(
                    this.agentCoaching,
                );

            return true;
        } catch (e) {
            return false;
        }
    }

    @action
    async updateAgentCoaching(completed?: boolean) {
        if (!this.agentCoaching) {
            console.warn("unable to create agent coaching");
            return true;
        }
        try {
            if (completed)
                this.agentCoaching.coachingStatus =
                    AgentCoachingStatus.Completed;
            else
                this.agentCoaching.coachingStatus =
                    AgentCoachingStatus.InProgress;

            await this.agentCoachingService.updateCoachingDraft(
                this.agentCoaching,
            );
            return true;
        } catch (e) {
            return false;
        }
    }

    @action
    async saveAgentResponse() {
        if (!this.agentCoaching) {
            console.warn("unable to update agent coaching");
            return true;
        }
        try {
            await this.agentCoachingService.updateAgentCoaching(
                this.agentCoaching,
            );
            return true;
        } catch (e) {
            return false;
        }
    }

    @action
    reset() {
        this.agentCoaching = undefined;
        this.targetedEvaluations = undefined;
    }
}
